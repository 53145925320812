import React, { FC, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./MarketsLiveCharts.module.scss";
import { getCommoditiesOverview } from "@/controllers/commodities";
import { getCurrenciesOverview } from "@/controllers/currencies";
import { Context } from "@/lib/Context";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Chart, Series } from "highcharts";
import { Box, Typography } from "@mui/material";
import { classNames, priceDiffPercentage } from "@/lib/utils";
import { useRecoilValue } from "recoil";
import { negativeState, positiveState, textColorState, themeState } from "@/lib/store";
import { CurrenciesCharts } from "@/models/currencies";
import { CommoditiesCharts } from "@/models/commodities";
import dayjs from "dayjs";
import Skeleton from "../skeleton/Skeleton";
import { addAccessibility, bindAxes } from "@/lib/highcharts.src";
import { isNull } from "lodash";
import { useWebSocket } from 'src/hooks/useWebSocket';

const keys: Record<string, string> = {
    "CO1:COM": "CO1:COM",
    "CL1:COM": "WTIUSDBCOMP",
    "NG1:COM": "NG1:COM",
    "XAUUSD:CUR": "XAUUSD:CUR",
    GBPUSDLITE: "GBPUSDLITE",
    EURUSDLITE: "EURUSDLITE",
    JPYUSDLITE: "JPYUSDLITE",
};

interface CardPropsTypes extends PropsTypes {
    derivative: CommoditiesCharts | CurrenciesCharts;
    obfuscated: boolean;
}

const chartMessageFunc = (liveCode) => {
    return {
        type: "TS",
        days_back: 3,
        ISIN: liveCode,
        currency: "IND2",
    };
};

const priceMessageFunc = (type: string, liveCode) => {
    if (type === "commodities") {
        return {
            type: "subscribe",
            ISIN: liveCode,
            currency: "",
            depth: "false",
        };
    } else {
        return {
            type: "subscribe",
            ISIN: liveCode,
            ticker: "1",
            market: "undefined",
            currency: "CURRENCY",
            depth: "false",
        };
    }
};


const addBreakLine = (data, theme) => {
    const oneHour = 60 * 60 * 1000;
    let processedData = [];

    for (let i = 1;i < data.length - 1;i++) {
        let currentPoint = data[i];
        let nextPoint = data[i + 1];

        // Calculate the time difference between consecutive points
        let timeDifference = nextPoint[0] - currentPoint[0];

        // If time difference is greater than 8 hour, add a marker to the current point
        if (timeDifference > oneHour * 8) {
            const svgBase64 =
                theme === "light"
                    ? "PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxOCcgaGVpZ2h0PScyNyc+PHRleHQgeD0nMCcgeT0nMTgnIGZvbnQtc2l6ZT0nMjAnIGZpbGw9J2JsYWNrJyBzdHlsZT0nZm9udC13ZWlnaHQ6IDEwMDA7IGxldHRlci1zcGFjaW5nOiAtMnB4Oyc+Ly88L3RleHQ+PC9zdmc+"
                    : "PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxOCcgaGVpZ2h0PScyNyc+PHRleHQgeD0nMCcgeT0nMTgnIGZvbnQtc2l6ZT0nMjAnIGZpbGw9J2xpZ2h0R3JleScgc3R5bGU9J2ZvbnQtd2VpZ2h0OiAxMDAwOyBsZXR0ZXItc3BhY2luZzogLTJweDsnPi8vPC90ZXh0Pjwvc3ZnPg==";

            processedData.push({
                x: currentPoint[0],
                y: currentPoint[1],
                marker: {
                    enabled: true,
                    symbol: `url(data:image/svg+xml;base64,${svgBase64})`,
                },
                dataLabels: {
                    enabled: true,
                    format: "Market Closed",

                    style: {
                        fontSize: "9px",
                    },
                },
            });
        }
        processedData.push(currentPoint);
    }
    return processedData;
};


const Card: FC<CardPropsTypes> = ({ derivative, type, obfuscated }) => {
    Series.prototype["bindAxes"] = bindAxes;
    const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement>; }>(null);

    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);

    const [price, setPrice] = useState<string | number>("N/A");
    const [priceColor, setPriceColor] = useState<string>(textColor);
    const [options, setOptions] = useState({
        chart: {
            margin: 0,
            skipClone: true,
            backgroundColor: "transparent",
            height: 100 / 4 - 10 + "%",
        },
        accessibility: {
            enabled: false,
        },
        responsive: {
            rules: [{ condition: { minWidth: 768, maxWidth: 1200 }, chartOptions: { chart: { height: 100 / 4 - 18 + "%" } } }],
        },
        series: [
            {
                data: [],
                lineWidth: 2,
                shadow: false,
                type: "spline",
                color: "#31ABA6",
                animation: false,
                showInLegend: false,
                marker: {
                    radius: 0,
                    animation: false,
                    lineColor: "transparent",
                    states: { hover: { radius: 4 } },
                },
                states: {
                    hover: {
                        halo: { size: 0 },
                        lineWidthPlus: 0,
                    },
                },
            },
        ],
        tooltip: {
            padding: 4,
            hideDelay: 0,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            animation: false,
            headerFormat: "",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            pointFormatter: function () {
                return `${this.y > 10 ? dayjs(this.x).format("DD/MM/YY HH:mm") + " - " + this.y.toFixed(2) : dayjs(this.x).format("DD/MM/YY HH:mm") + " - " + this.y.toFixed(4)}`;
            },
            style: {
                fontSize: "12px",
                color: "#fff",
                fontFamily: "Roboto",
            },
        },
        plotOptions: {
            series: {
                shadow: false,
                animation: false,
                marker: { animation: false },
            },
        },
        title: { text: "" },
        yAxis: { visible: false },
        xAxis: { visible: false, ordinal: true },
        credits: { enabled: false },
        exporting: { enabled: false },
    });

    const priceMessage = JSON.stringify(priceMessageFunc(type, derivative.liveCode));
    const chartMessage = JSON.stringify(chartMessageFunc(derivative.liveCode));

    const updateChartData = useCallback(
        ({ data }: MessageEvent<any>) => {
            const { ISIN, ts, "ts-last": tsLast } = JSON.parse(data);
            if (derivative?.liveCode === ISIN) {
                if (ts) {
                    setOptions(prev => ({
                        ...prev,
                        series: [{
                            ...prev.series[0],
                            data: addBreakLine(ts, theme),
                            type: "spline",
                            name: derivative?.fullName,
                        }],
                    }));
                }
                !isNull(tsLast?.[1]) && !isNull(derivative?.lastClosePrice) ?
                    setPrice(priceDiffPercentage(tsLast?.[1], derivative?.lastClosePrice).toFixed(2)) :
                    setPrice("N/A");
            }
            if (keys[derivative?.liveCode] === ISIN) {
                const { update } = JSON.parse(data);
                setPrice(update?.pch);
            }
        },
        [derivative, theme]
    );

    const updatePriceData = useCallback(
        ({ data }: MessageEvent<any>) => {
            const { ISIN, ts, "ts-last": tsLast } = JSON.parse(data);
            if (derivative?.liveCode === ISIN) {
                !isNull(tsLast?.[1]) && !isNull(derivative?.lastClosePrice) ?
                    setPrice(priceDiffPercentage(tsLast?.[1], derivative?.lastClosePrice).toFixed(2)) :
                    setPrice("N/A");
            }
            if (keys[derivative?.liveCode] === ISIN) {
                const { update } = JSON.parse(data);
                setPrice(update?.pch);
            }
        },
        [derivative, theme]
    );

    const { isLoading } = useWebSocket("multiWebSocket",
        `markets-live-${derivative?.liveCode}`,
        chartMessage,
        updateChartData, [], undefined, undefined, true
    );

    useWebSocket("multiWebSocket",
        `markets-live-${derivative?.liveCode}`,
        priceMessage,
        updatePriceData
    );

    useEffect(() => {
        const newPriceColor = Number(price) < 0 ? negative : positive;
        setPriceColor(newPriceColor);
        setOptions((prevState) => ({
            ...prevState,
            series: [
                {
                    ...prevState.series[0],
                    color: newPriceColor,
                },
            ],
        }));
    }, [price]);

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            series: [
                {
                    ...prevState.series[0],
                    data: addBreakLine(prevState.series[0].data, theme),
                },
            ],
        }));
    }, [theme]);

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
                className={classNames(obfuscated && "obfuscated")}
            >
                <Typography variant="body2">{derivative?.fullName}</Typography>
                <div style={{ display: "flex" }}>
                    <Typography variant="subtitle2" sx={{ color: textColor }}>
                        Today: &nbsp;
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", color: priceColor }}>
                        {/* @ts-ignore */}
                        {isNaN(price) || price === "N/A" ? "N/A" : `${Number(price) > 0 ? "+" : ""}${price}%`}
                    </Typography>
                </div>
            </Box>
            <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} ref={chartRef} />
        </div>
    );
};

interface PropsTypes {
    type: "commodities" | "currencies";
}

const MarketsLiveCharts: FC<PropsTypes> = ({ type }) => {
    const [derivatives, setDerivatives] = useState<Array<CommoditiesCharts | CurrenciesCharts>>([]);
    const [obfuscated, setObfuscated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    addAccessibility(Highcharts);

    useEffect(() => {
        (type === "commodities" ? getCommoditiesOverview() : getCurrenciesOverview())
            .then((res) => {
                const obfuscatedProp = res.find((item) => item.obfuscated);
                if (obfuscatedProp) {
                    setObfuscated(true);
                }
                setDerivatives(res.filter((item) => !item.obfuscated && item.liveCode !== "BTCUSDLITE"));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={styles.charts}>
            {isLoading ? (
                <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading} />
            ) : (
                derivatives.map((derivative: CommoditiesCharts | CurrenciesCharts) => <Card derivative={derivative} type={type} key={derivative.liveCode} obfuscated={obfuscated} />)
            )}
        </div>
    );
};

export default MarketsLiveCharts;
